export const randomString = (num = 5) =>
  Math.random()
    .toString(36)
    .substr(2, num);
// make this account for spaces like a double name

export const properNoun = arg => {
  if (typeof arg !== "string" || !arg.trim()) return arg; // Check if it's a string and not empty
  return arg
    .split(" ") // Split into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter, lowercase the rest
    .join(" "); // Join the words back together
};

export const isEmpty = arg => Boolean(!arg || !arg.trim());
