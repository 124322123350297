import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import FinalRosterExportContext from "../../contexts/FinalRosterExportContext";
import logo from "../../assets/logo.png";
import logoWithQual from "../../assets/logo-with-qual.png";
import { formatDate } from "../../utils/DateUtils";
import { MAIN, QUALIFIERS } from "../../constants/TournamentTypeConstants";
import FlagHolder from "../elements/WBCFlagHolder";
import { properNoun } from "../../utils/StringUtils";

const Pages = styled.div`
  position: absolute;
  margin: 0;
  background-color: white;
  font-family: Roboto, Arial, sans-serif;
`;

const Page = styled.div`
  height: 8.25in;
  width: 10.5in;
  page-break-after: always;
  display: flex;
  flex-direction: column;
  background: white;
`;

const LoadingPage = styled(Page)`
  align-items: center;
  justify-content: center;
  #loading-wrapper {
    display: flex;
    font-size: 24px;
    img {
      -webkit-animation: spin 4s linear infinite;
      -moz-animation: spin 4s linear infinite;
      animation: spin 4s linear infinite;
    }
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: navy;
  z-index: 1055; // place in front of navbar
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  z-index: 1056; // place in front of wbc-footer
  table {
    margin-bottom: 0.5rem;
    thead {
      border-bottom: 1px solid black;
    }
    tr {
      line-height 0.6rem;
      font-size: 0.6rem;
    }
    td {
      padding: 0.125rem;
      &.td-bold {
        font-weight: bold;
      }
    }
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  top: -24px;
  position: relative;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  padding-right: 0.125rem;
  overflow: hidden;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NumericalGuide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  padding: 0.5rem;
  border: 1px solid black;
  & .numerical-guide-header {
    display: flex;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  & table {
    display: flex;
    border: none;
  }
`;

const FlagContainer = styled(FlagHolder)`
  margin-top: 3rem;
`;

const FinalRosterExportDashboard = () => {
  const { isLoading, rostersByFed } = useContext(FinalRosterExportContext);
  const [displayData, setDisplayData] = useState([]);

  const FLAG_URL = window.MLBBest.envVariables.REACT_APP_FLAG_URL;

  const formatBirthDate = useCallback(profile => {
    if (profile.birthDate) {
      return formatDate(profile.birthDate, "DD-MMM-YYYY");
    } else {
      return "-";
    }
  }, []);

  const formatBirthPlace = useCallback(profile => {
    const birthCountry = profile?.birthCountry === "Dominican Republic" ? "Dominican Rep." : profile.birthCountry;
    const birthCity = ["TBD", "N/A"].includes(profile?.birthCity?.toUpperCase())
      ? profile.birthCity.toUpperCase()
      : properNoun(profile?.birthCity);
    if (profile.birthCountry === "United States" && profile.birthCity && profile.birthStateCode) {
      return `${birthCity}, ${profile.birthStateCode.toUpperCase()}`;
    } else if (profile.birthCity && profile.birthCountry) {
      return `${birthCity}, ${birthCountry}`;
    } else if (profile.birthCountry) {
      return profile.birthCountry;
    } else {
      return "-";
    }
  }, []);

  const formatHeight = useCallback(height => {
    if (height > 0) {
      // convert from inches to feet and inches
      const feet = Math.floor(height / 12);
      const inches = height % 12;
      return `${feet}' ${inches}"`;
    }
    return "-";
  }, []);

  const formatProfile = useCallback(
    profile => {
      return {
        profileId: profile?.profileId,
        profileType: profile?.profileType,
        name: `${properNoun(profile?.lastName)}, ${properNoun(profile?.firstName)}`,
        uniformNumber: profile?.uniformNumber || "-",
        positionCategory: profile?.positionCategory || "-",
        position: profile?.position || "-",
        bats: profile?.bats?.toUpperCase() || "-",
        throws: profile?.throwingArm?.toUpperCase() || "-",
        height: formatHeight(profile?.height),
        weight: profile?.weight || "-",
        birthDate: formatBirthDate(profile),
        birthPlace: formatBirthPlace(profile),
        org: profile?.org && profile.org.toUpperCase() !== "NO" ? profile.org : "-",
        club: profile?.club || "-"
      };
    },
    [formatBirthDate, formatBirthPlace, formatHeight]
  );

  const renderNumericalGuideRow = useCallback(
    (player, country) => (
      <tr key={`${country}-numerical-guide-row-${player?.profileId}`}>
        <td className="text-wrap">{player.uniformNumber}</td>
        <td className="text-wrap">{player.name}</td>
        <td className="text-wrap">{player.position}</td>
      </tr>
    ),
    []
  );

  const renderPlayerRow = useCallback(
    (player, country) => (
      <tr key={`${country}-player-row-${player?.profileId}`}>
        <td className="td-bold">{player.uniformNumber}</td>
        <td className="text-wrap">{`${player.name}`}</td>
        <td className="text-wrap">{player.position}</td>
        <td className="text-wrap">{player.bats}</td>
        <td className="text-wrap">{player.throws}</td>
        <td className="text-wrap">{player.height}</td>
        <td className="text-wrap">{player.weight}</td>
        <td className="text-wrap">{player.birthDate}</td>
        <td className="text-wrap">{player.birthPlace}</td>
        <td className="text-wrap">{player.org}</td>
        <td className="text-wrap">{player.club}</td>
      </tr>
    ),
    []
  );

  const renderStaffRow = useCallback(
    (staffMember, country) => (
      <tr key={`${country}-staff-row-${staffMember?.profileId}`}>
        <td className="text-wrap">{staffMember.name}</td>
        <td className="text-wrap">{staffMember.position}</td>
        <td className="text-wrap">{staffMember.birthPlace}</td>
        <td className="text-wrap">{staffMember.org}</td>
        <td className="text-wrap">{staffMember.club}</td>
      </tr>
    ),
    []
  );

  const allDisplayData = useMemo(() => {
    if (rostersByFed && Object.entries(rostersByFed)?.length > 0) {
      return Object.entries(rostersByFed)
        .map(([country, roster]) => {
          const players = roster?.players
            .map(player => formatProfile(player))
            .sort((a, b) => a?.name?.localeCompare(b?.name));
          const playersNumerical = roster?.players
            .map(player => formatProfile(player))
            .sort((a, b) => a?.uniformNumber - b?.uniformNumber);

          const fedDisplayData = {
            country,
            countryCode: roster?.countryCode?.toLowerCase(),
            fedTeamId: players?.[0]?.fedTeamId,
            tournamentType: players?.[0]?.tournamentType === 1 ? QUALIFIERS : MAIN,
            numericalGuide: playersNumerical,
            staff: roster?.staff
              .map(staffMember => formatProfile(staffMember))
              .sort((a, b) => a?.name?.localeCompare(b?.name)),
            byPosition: {
              pitchers: players.filter(p => p.positionCategory === "Pitcher").sort((a, b) => a?.lastName - b?.lastName),
              catchers: players.filter(p => p.positionCategory === "Catcher").sort((a, b) => a?.lastName - b?.lastName),
              infielders: players
                .filter(p => p.positionCategory === "Infield")
                .sort((a, b) => a?.lastName - b?.lastName),
              outfielders: players
                .filter(p => p.positionCategory === "Outfield")
                .sort((a, b) => a?.lastName - b?.lastName)
            }
          };

          return fedDisplayData;
        })
        .sort((a, b) => a?.country?.localeCompare(b?.country));
    }
    return [];
  }, [rostersByFed, formatProfile]);

  useEffect(() => {
    const elementSelectorsToRemove = ["#best-navbar", "#wbc-footer"];
    elementSelectorsToRemove.forEach(selector => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.display = "none";
      }
    });
  }, []);

  useEffect(() => {
    setDisplayData(allDisplayData);
  }, [allDisplayData]);

  return (
    <>
      {isLoading ? (
        <LoadingPage>
          <div id="loading-wrapper">
            <div className="d-flex mr-2">
              <img src={logo} height="48px" width="48px" alt="wbc-logo" />
            </div>
            Loading...
          </div>
        </LoadingPage>
      ) : (
        <Pages id="dashboard">
          {displayData.map(c => (
            <Page key={c.country}>
              <Header className="header">{c.country}</Header>
              <Body>
                <LeftContainer>
                  <div className="d-flex justify-content-center">
                    <img className="wbc-logo" src={logoWithQual} height="82px" width="180px" alt="wbc-logo" />
                  </div>
                  <NumericalGuide id="numerical-guide">
                    <div className="numerical-guide-header">Numerical Guide</div>
                    <table className="table table-responsive table-sm table-borderless">
                      <tbody>{c.numericalGuide.map(player => renderNumericalGuideRow(player, c.country))}</tbody>
                    </table>
                  </NumericalGuide>
                  <FlagContainer
                    style={{ height: "105px", width: "195px" }}
                    src={`${FLAG_URL}/${c?.countryCode}.svg`}
                  />
                </LeftContainer>
                <RightContainer>
                  {Object.entries(c.byPosition).map(([position, players]) => (
                    <TableContainer key={`${c.country}-${position})}`}>
                      <table className="table table-responsive table-sm table-borderless">
                        <thead>
                          <tr>
                            <th style={{ width: "0.5in" }}>NO</th>
                            <th style={{ width: "1.5in" }}>
                              {position.toUpperCase()} ({players?.length || 0})
                            </th>
                            <th style={{ width: "0.5in" }}>POS</th>
                            <th style={{ width: "0.25in" }}>B</th>
                            <th style={{ width: "0.25in" }}>T</th>
                            <th style={{ width: "0.45in" }}>HT</th>
                            <th style={{ width: "0.45in" }}>WT</th>
                            <th style={{ width: "0.75in" }}>DOB</th>
                            <th style={{ width: "2.25in" }}>BIRTHPLACE</th>
                            <th style={{ width: "0.65in" }}>MLB ORG</th>
                            <th style={{ width: "1.5in" }}>CLUB</th>
                          </tr>
                        </thead>
                        <tbody>{players.map(player => renderPlayerRow(player, c.country))}</tbody>
                      </table>
                    </TableContainer>
                  ))}
                  <TableContainer>
                    <table className="table table-responsive table-sm table-borderless">
                      <thead>
                        <tr>
                          <th style={{ width: "1.75in" }}>COACHING STAFF</th>
                          <th style={{ width: "1in" }}>POSITION</th>
                          <th style={{ width: "2in" }}>BIRTHPLACE</th>
                          <th style={{ width: "1in" }}>MLB ORG</th>
                          <th style={{ width: "3in" }}>CLUB</th>
                        </tr>
                      </thead>
                      <tbody>{c.staff?.map(staffMember => renderStaffRow(staffMember, c.country))}</tbody>
                    </table>
                  </TableContainer>
                </RightContainer>
              </Body>
            </Page>
          ))}
        </Pages>
      )}
    </>
  );
};

export default FinalRosterExportDashboard;
